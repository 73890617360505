<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div class="ml-2">
                    <div class="text-4xl text-dark font-bold">
                      MTN HOODS Hourly Summary Report
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="col comp-grid">
            <div class="flex align-items-center justify-content-around">
              <div
                v-if="searchText"
                class="surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-cente"
              >
                Search
                <Chip
                  class="font-medium px-2 py-1"
                  removable
                  @remove="clearSearch()"
                  >{{ searchText }}</Chip
                >
              </div>
            </div>
            <div class="">
              <div>
                <template v-if="showBreadcrumbs && $route.query.tag">
                  <Breadcrumb
                    :home="{ icon: 'pi pi-home', to: '/news' }"
                    :model="pageBreadCrumb"
                  />
                </template>
                <div class="grid">
                  <!-- Master Page Start -->
                  <div class="col-12">
                    <!-- page records template -->
                    <div class="page-records" v-if="!loading">
                      <DataTable
                        :lazy="true"
                        :loading="loading"
                        :value="records"
                        dataKey="news_id"
                        @sort="onSort($event)"
                        class=""
                        :showGridlines="false"
                        :rowHover="true"
                        responsiveLayout="stack"
                      >
                        <Column
                          field="TimePeriod"
                          header="Period"
                        >
                          <template #body="{ data }">
                            {{ data.TimePeriod }}
                          </template>
                        </Column>
                        <Column field="DateAdded" header="Date Added">
                          <template #body="{ data }">
                            {{ $utils.humanDatetime(data.DateAdded) }}
                          </template>
                        </Column>
                        <Column field="Hits" header="Hits">
                          <template #body="{ data }">
                            {{ data.Hits }}
                          </template>
                        </Column>
                        <Column field="UniqueMSISDN" header="Unique MSISDNs">
                          <template #body="{ data }">
                            {{ data.UniqueMSISDN }}
                          </template>
                        </Column>
                        <Column field="UniqueVisits" header="Unique Visits">
                          <template #body="{ data }">
                            {{ data.UniqueVisits }}
                          </template>
                        </Column>
                        <Column
                          field="AvgHits_MSISDN"
                          header="AvgHits MSISDN"
                        >
                          <template #body="{ data }">
                            {{ data.AvgHits_MSISDN }}
                          </template>
                        </Column>
                        <Column
                          field="AvgHits_Session"
                          header="AvgHits Session"
                        >
                          <template #body="{ data }">
                            {{ data.AvgHits_Session }}
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                    <!-- page loading indicator -->
                    <template v-if="loading">
                      <div>
                        <DataTable
                          :value="[1, 2, 3, 4, 5]"
                          responsiveLayout="scroll"
                        >
                          <Column>
                            <template #body>
                              <Skeleton />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton borderRadius="16px" />
                            </template>
                          </Column>
                          <Column>
                            <template #body>
                              <Skeleton />
                            </template>
                          </Column>
                          <Column style="width: 3rem">
                            <template #body>
                              <Skeleton size="2rem" />
                            </template>
                          </Column>
                        </DataTable>
                      </div>
                    </template>
                    <!-- end of page loading indicator-->
                    <!-- Empty record -->
                    <template v-if="!loading && !records.length">
                      <div
                        class="p-3 my-3 text-500 text-lg font-medium text-center"
                      >
                        No record found
                      </div>
                    </template>
                    <!-- end of empty record-->
                    <!-- pagination component-->
                    <template v-if="showFooter">
                      <div class="">
                        <div v-show="!loading">
                          <div class="flex justify-content-between">
                            <div
                              v-if="paginate && totalPages > 1"
                              class="flex-grow-1"
                            >
                              <Paginator
                                @page="
                                  (event) => {
                                    pagination.page = event.page + 1;
                                  }
                                "
                                :rows="pagination.rowsPerPage"
                                :totalRecords="totalRecords"
                              >
                                <template #start>
                                  <span class="text-sm text-gray-500 px-2">
                                    Records
                                    <b
                                      >{{ recordsPosition }} of
                                      {{ totalRecords }}</b
                                    >
                                  </span>
                                </template>
                                <template #end> </template>
                              </Paginator>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <!-- end of pagination component-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { defineAsyncComponent, ref } from "vue";
import { PageMixin } from "../../mixins/page.js";
import { ListPageMixin } from "../../mixins/listpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "hoodssummaryreportNewsPage",
  components: {},
  mixins: [PageMixin, ListPageMixin],
  props: {
    primaryKey: {
      type: String,
      default: "news_id",
    },
    pageName: {
      type: String,
      default: "news",
    },
    routeName: {
      type: String,
      default: "newshoodssummaryreport",
    },
    apiPath: {
      type: String,
      default: "news/hoodssummaryreport",
    },
    mergeRecords: {
      type: Boolean,
      default: false,
    },
    multiCheckbox: {
      type: Boolean,
      default: true,
    },
    msgBeforeDelete: {
      type: String,
      default: "Are you sure you want to delete this record?",
    },
  },
  data() {
    return {
      pageExportFormats: [
        {
          label: "Excel",
          icon: "pi pi-file-excel text-green-500",
          command: () => {
            this.exportPageRecords("excel", "xlsx");
          },
        },
        {
          label: "Csv",
          icon: "pi pi-table text-green-200",
          command: () => {
            this.exportPageRecords("csv", "csv");
          },
        },
      ],
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        //set browser page title
        return "MTN HOODS Hourly Summary Report";
      },
    },
    records: {
      get: function () {
        return this.$store.getters["news/records"];
      },
      set: function (value) {
        this.$store.commit("news/setRecords", value);
      },
    },
    currentRecord: {
      get: function () {
        return this.$store.getters["news/currentRecord"];
      },
      set: function (value) {
        this.$store.commit("news/setCurrentRecord", value);
      },
    },
  },
  watch: {
    apiUrl: function () {
      this.load();
    },
  },
  methods: {
    ...mapActions("news", ["fetchRecords", "deleteRecord"]),
    getActionMenuModel(data) {
      return [
        {
          label: "View",
          to: `/news/view/${data.news_id}`,
          icon: "pi pi-eye",
        },
        {
          label: "Edit",
          to: `/news/edit/${data.news_id}`,
          icon: "pi pi-pencil",
        },
        {
          label: "Delete",
          command: (event) => {
            this.deleteItem(data.news_id);
          },
          icon: "pi pi-minus-circle",
        },
      ];
    },
    load: function () {
      if (!this.loading) {
        this.loading = true;
        this.currentRecord = null;
        let url = this.apiUrl;
        let payload = {
          url,
          merge: this.mergeRecords,
        };
        this.fetchRecords(payload).then(
          (response) => {
            this.loading = false;
            this.ready = true;
            this.totalRecords = response.total_records;
            this.recordCount = response.record_count;
            this.pagination.rowsNumber = this.totalRecords;
            window.scrollTo(0, 0);
          },
          (response) => {
            this.loading = false;
            this.showPageRequestError(response);
          }
        );
      }
    },
  },
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
